<template>
    <div v-if="availableTime">

            <div class="list-group">
                <div class="list-group-item list-group-item-secondary flex-column align-items-start">
                    <div class="d-flex w-40 justify-content-between">
                        <h5 style="margin-bottom: 0; font-weight: bold!important;">Monte ore</h5>
                        <h5 style="margin-bottom: 0"><b-icon icon="clock-history"/></h5>
                    </div>
                </div>
                <!-- GUIDE REALI -->
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start" v-on:click.capture="openEditor('practice')">
                    <div class="d-flex w-40 justify-content-between text-right">
                        <h5 class="mb-1"> Guida</h5>
                        <b><b-icon icon="record-fill" :variant="setVariantByTimeLeft(availableTime.secondsLeftPractice)"/>
                            {{ formatRemainingTime(availableTime.secondsLeftPractice) }}<br>
                            <span class="text-muted" v-if="!bookedTime.practice">Nessuna prenotazione</span>
                            <span class="text-muted" v-if="bookedTime.practice">di cui {{ formatRemainingTime(bookedTime.practice) }} prenotate<br></span>
                            <span v-if="bookedTime.practice">rimangono {{ formatRemainingTime(availableTime.secondsLeftPractice - bookedTime.practice) }}</span>
                        </b>
                    </div>

                    <div v-if="hasRole('owner')" >
                        <small v-if="!addTimePractice"><b-icon icon="pencil"/> Clicca per aggiungere altre ore.</small>
                        <div class="shadow card" style="padding: 15px; background-color: #eeeeee"
                             v-if="addTimePractice">
                            <available-time-add
                                :available-time-id="availableTime.id"
                                :student-id="studentId"
                                type="practice"
                                v-on:updated-available-time="updatedAvailableTime('practice')"
                                v-on:reset-available-time="updatedAvailableTime('practice')"
                                v-on:closed-editor="addTimePractice = false"
                            />
                        </div>
                    </div>
                </a>
                <!-- SIMULAZIONE -->
                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start" v-on:click.capture="openEditor('simulator')">
                    <div class="d-flex w-40 justify-content-between text-right">
                        <h5 class="mb-1"> Simulazione</h5>
                        <b><b-icon icon="record-fill" :variant="setVariantByTimeLeft(availableTime.secondsLeftSimulator)"/>
                            {{ formatRemainingTime(availableTime.secondsLeftSimulator) }}<br>
                            <span class="text-muted" v-if="!bookedTime.simulator">Nessuna prenotazione</span>
                            <span class="text-muted" v-if="bookedTime.simulator">di cui {{ formatRemainingTime(bookedTime.simulator) }} prenotate<br></span>
                            <span  v-if="bookedTime.simulator">rimangono {{ formatRemainingTime(availableTime.secondsLeftSimulator - bookedTime.simulator) }}</span>
                        </b>
                    </div>

                    <div v-if="hasRole('owner')" >
                        <small v-if="!addTimeSimulator"><b-icon icon="pencil"/> Clicca per aggiungere altre ore.</small>
                        <div class="shadow card" style="padding: 15px; background-color: #eeeeee"
                             v-if="addTimeSimulator">
                            <available-time-add
                                :available-time-id="availableTime.id"
                                :student-id="studentId"
                                type="simulator"
                                v-on:updated-available-time="updatedAvailableTime('simulator')"
                                v-on:reset-available-time="updatedAvailableTime('simulator')"
                                v-on:closed-editor="addTimeSimulator = false"
                            />
                        </div>
                    </div>
                </a>

            </div>
    </div>
</template>

<script>
import AvailableTimeDataService from "@/components/available_time/AvailableTimeDataService";
import AvailableTimeAdd from "@/components/available_time/AvailableTimeAdd";
import BookingDataService from "@/components/booking/BookingDataService";
import moment from 'moment';

export default {
    name: "available-time-view",
    components: {AvailableTimeAdd},
    data() {
        return {
            availableTime: null,
            bookedTime: null,
            addTimeSimulator: false,
            addTimePractice: false,
            message: '',
            errorMsg: ''
        };
    },
    props: ['studentId'],
    methods: {
        getAvailableTime(studentId) {
            AvailableTimeDataService.getByStudent(studentId)
                .then(response => {
                    if (!response.data) {
                        this.availableTime = {
                            secondsLeftSimulator: 0,
                            secondsLeftPractice: 0,
                            studentId: null,
                        }
                    } else this.availableTime = response.data;

                    this.bookedTime = {
                        simulator: 0,
                        practice: 0,
                    }
                    BookingDataService.getCountSecondsByStudentAndDate(this.studentId, moment().format('YYYY-MM-DD'), false)
                        .then(response => {
                            this.bookedTime.practice = response.data.seconds;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });
                    BookingDataService.getCountSecondsByStudentAndDate(this.studentId, moment().format('YYYY-MM-DD'), true)
                        .then(response => {
                            this.bookedTime.simulator = response.data.seconds;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        formatRemainingTime(seconds) {
            if (seconds == 0 || seconds === null || typeof seconds === 'undefined') return '/';

            let prefix = '';

            if(seconds < 0) {
                // negative value
                prefix = '-';
                seconds = Math.abs(seconds);
            }

            const d = Number(seconds);
            const h = Math.floor(d / 3600);
            const m = Math.floor(d % 3600 / 60);
            const s = Math.floor(d % 3600 % 60);

            const hDisplay = h > 0 ? h + (h == 1 ? " ora, " : " ore, ") : "";
            const mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minuti, ") : "";
            const sDisplay = s > 0 ? s + (s == 1 ? " secondo, " : " secondi, ") : "";
            let string = hDisplay + mDisplay + sDisplay;

            return prefix + string.substring(0, string.length - 2);
        },
        setVariantByTimeLeft(time) {
            const hour = 3600;
            if (time < 0) {
                return 'danger';
            } else if (time == 0 || time == null) {
                return 'secondary';
            } else if (time < 5 * hour) {
                return 'warning';
            } else {
                return 'success';
            }
        },
        openEditor(type) {
            switch (type) {
                case 'simulator':
                    this.addTimeSimulator = true;
                    this.addTimePractice = false;
                    break;
                case 'practice':
                    this.addTimeSimulator = false;
                    this.addTimePractice = true;
                    break;
            }
        },
        updatedAvailableTime(type) {
            this.getAvailableTime(this.studentId);
            switch (type) {
                case 'simulator':
                    this.addTimeSimulator = false;
                    break;
                case 'practice':
                    this.addTimePractice = false;
                    break;
            }
        },

    },
    mounted() {
        this.message = '';
        this.getAvailableTime(this.studentId);
    }
}
</script>

<style scoped>

</style>