import http from "../../helpers/http-common";

class AvailableTimeDataService {
    getAll() {
        return http.get("/available_times");
    }

    get(id) {
        return http.get(`/available_times/${id}`);
    }

    getByStudent(studentId) {
        return http.get(`/available_times/by_student/${studentId}`);
    }

    create(data) {
        return http.post("/available_times", data);
    }

    update(id, data) {
        return http.put(`/available_times/${id}`, data);
    }

    delete(id) {
        return http.delete(`/available_times/${id}`);
    }

    deleteAll() {
        return http.delete('/available_times');
    }

}

export default new AvailableTimeDataService();
