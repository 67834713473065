<template>
    <div>

        <h6>Aggiungi tempo</h6>
        <div class="row">
            <div class="col-sm-3">
                <b-form @submit.prevent.stop="addAvailableTime">
                    <b-form-group id="hour" size="sm" label="" label-for="hour" description="">
                        <b-form-input v-model="hour" placeholder="Ore"></b-form-input>
                    </b-form-group>
                </b-form>
            </div>
            <div class="col-sm-9">
                <div class="action-bar row">
                    <div class="col-sm-8">
                        <button class="btn btn-success btn-sm" @click="addAvailableTime" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                        <b-button variant="link" size="sm" @click="closeEditor" :disabled="this.$store.state.loading"><b-icon icon="x"/> Annulla</b-button>
                    </div>
                    <div class="col-sm-4 text-right">
                        <b-button variant="outline-danger" size="sm" @click="resetAvailableTime" :disabled="this.$store.state.loading"> <b-icon icon="trash"/> reset</b-button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import AvailableTimeDataService from "@/components/available_time/AvailableTimeDataService";

export default {
    name: "available-time-add",
    data() {
        return {
            currentAvailableTime: null,
            hour: null,
            message: '',
            errorMsg: '',
        };
    },
    props: ["availableTimeId","studentId","type"],
    methods: {
        getAvailableTime(id) {
            AvailableTimeDataService.get(id)
                .then(response => {
                    this.currentAvailableTime = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        addAvailableTime() {
            if (this.currentAvailableTime) {
                this.updateAvailableTime();
            } else {
                this.createAvailableTime()
            }
        },

        createAvailableTime() {
            if (this.hour) {
                this.currentAvailableTime = {
                    studentId: this.studentId,
                    secondsLeftSimulator: 0,
                    secondsLeftPractice: 0
                }
                if (this.type == 'simulator') {
                    this.currentAvailableTime.secondsLeftSimulator = this.hour * 60 * 60;
                }
                if (this.type == 'practice') {
                    this.currentAvailableTime.secondsLeftPractice = this.hour * 60 * 60;
                }
                console.log(this.currentAvailableTime)
                AvailableTimeDataService.create(this.currentAvailableTime)
                    .then(response => {
                        this.message = 'Available time was updated successfully!';
                        this.$emit('updated-available-time', this.currentAvailableTime);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        updateAvailableTime() {
            if (this.hour) {
                if (this.type == 'simulator') {
                    this.currentAvailableTime.secondsLeftSimulator += this.hour * 60 * 60;
                }
                if (this.type == 'practice') {
                    this.currentAvailableTime.secondsLeftPractice += this.hour * 60 * 60;
                }
                AvailableTimeDataService.update(this.currentAvailableTime.id, this.currentAvailableTime)
                    .then(response => {
                        this.message = 'Available time was updated successfully!';
                        this.$emit('updated-available-time', this.currentAvailableTime);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        resetAvailableTime() {
            if(confirm(`Vuoi resettare il tempo dello studente a 0?`)) {
                if (this.type == 'simulator') {
                    this.currentAvailableTime.secondsLeftSimulator = 0;
                }
                if (this.type == 'practice') {
                    this.currentAvailableTime.secondsLeftPractice = 0;
                }
                AvailableTimeDataService.update(this.currentAvailableTime.id, this.currentAvailableTime)
                    .then(response => {
                        this.$emit('reset-available-time');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        if (this.availableTimeId) {
            this.getAvailableTime(this.availableTimeId);
        }
    }
};
</script>

<style>
.action-bar {
    margin-top: 3px!important;
}
</style>
