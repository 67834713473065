import http from "../../helpers/http-common";

class BookingDataService {
    getAllByInstructor(id) {
        return http.get(`/bookings/instructor/${id}`);
    }

    getAllByStudentAndDate(studentId, dateStart) {
        return http.get(`/bookings/by-student-date/${studentId}/${dateStart}`);
    }

    getAllByDate(dateStart, dateEnd) {
        return http.get(`/bookings/by-date/${dateStart}/${dateEnd}`);
    }

    getIncludeByDate(dateStart, dateEnd) {
        return http.get(`/bookings/include-by-date/${dateStart}/${dateEnd}`);
    }

    getIntersectByDate(dateStart, dateEnd) {
        return http.get(`/bookings/intersect-by-date/${dateStart}/${dateEnd}`);
    }
    
    getCountSecondsByStudentAndDate(studentId, dateStart, isVirtual = false) {
        return http.get(`/bookings/count-seconds-by-student-date/${studentId}/${dateStart}/${isVirtual}`);
    }

    getAll() {
        return http.get("/bookings");
    }

    get(id) {
        return http.get(`/bookings/${id}`);
    }

    create(data) {
        return http.post("/bookings", data);
    }

    update(id, data) {
        return http.put(`/bookings/${id}`, data);
    }

    delete(id) {
        return http.delete(`/bookings/${id}`);
    }
}

export default new BookingDataService();
