<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-9 section-title">
                <h3>Monte ore</h3>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>



        <div class="top-content ">
            <b-row>
                <b-col md="8">
                    <p>In questa sezione è possibile visualizzare la quantità di ore di pratica rimanenti</p>
                </b-col>
                <b-col md="4">
                    <router-link to="purchase-available-times" class="btn btn btn-primary btn-block">Acquista subito altre ore</router-link>
                </b-col>
            </b-row>
        </div>

        <div class="content">
            <div class="index opened">
                <div class="card">
                    <available-time-view :student-id="studentId" v-if="studentId"/>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import AvailableTimeView from "@/components/available_time/AvailableTimeView";

export default {
    name: "available-times-student-index",
    components: {AvailableTimeView},
    data() {
        return {
            studentId: null
        }
    },
    mounted() {
        this.studentId = this.$store.state.user.profileId;
    }
};
</script>


<style>
</style>
